export default {
  addFavoriteToCart: {
    id: 'dashboard_my_favorites_add_to_cart',
    defaultMessage: 'Add to cart',
  },
  toastAddToCartTitle: {
    id: 'favorites_add_to_cart_toast_title',
    defaultMessage: 'Yuhuu!',
  },
  toastAddToCartContent: {
    id: 'favorites_add_to_cart_toast_description',
    defaultMessage: 'Item added to cart',
  },
}
