export const ToastName = {
  SavedFavorite: 'saved-favorite-toast',
  AlreadySavedFavorite: 'already-saved-favorite-toast',
  RemoveFavorite: 'remove-favorite-toast',
}

export const TRACKING_ZONE = 'favorites_toast'

export const TrackingName = {
  SeeFavorites: 'see_my_favorites',
  AddedToFavorites: 'added_to_my_favorites',
  AlreadyInFavorites: 'already_in_my_favorites',
}

export type FavoritesToastState = {
  title: string
  content: string
  actionText: string
  closeAlternativeText: string
  duration: number
  isOpen: boolean
  name: string
  type: 'error' | 'success'
}

export const defaultState: FavoritesToastState = {
  title: '',
  content: '',
  actionText: '',
  closeAlternativeText: '',
  duration: 10_000,
  isOpen: false,
  name: ToastName.SavedFavorite,
  type: 'success',
}
