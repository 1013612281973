export default {
  savedFavTitle: {
    id: 'favorites_toast_save_title',
    defaultMessage: 'Saved and faved!',
  },
  savedFavContent: {
    id: 'favorites_toast_save_description',
    defaultMessage: '(And a cheesy rhyme to boot)',
  },
  savedFavActionText: {
    id: 'favorites_toast_save_button_label',
    defaultMessage: 'See my favorites',
  },

  alreadySavedFavTitle: {
    id: 'favorites_toast_already_saved_title',
    defaultMessage: 'Welcome again!',
  },
  alreadySavedFavContent: {
    id: 'favorites_toast_already_saved_description',
    defaultMessage: 'You faved this product one day',
  },
  alreadySavedFavActionText: {
    id: 'favorites_toast_already_saved_button_label',
    defaultMessage: 'See my favorites',
  },

  removeFavTitle: {
    id: 'favorites_toast_undo_title',
    defaultMessage: 'Not ready to say goodbye?',
  },
  removeFavContent: {
    id: 'favorites_toast_undo_description',
    defaultMessage: 'Press undo.',
  },
  removeFavActionText: {
    id: 'favorites_toast_undo_button_label',
    defaultMessage: 'Undo',
  },

  closeToast: {
    id: 'toast_close_alternative_text',
    defaultMessage: 'Close',
  },
}
