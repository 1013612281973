import { computed, reactive } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import {
  type FavoritesToastState,
  ToastName,
  defaultState,
} from './FavoritesToast'
import translations from './FavoritesToast.translations'

class FavoritesToast {
  #i18n = useI18n()

  constructor(public toastInfo: FavoritesToastState) {
    this.toastInfo = toastInfo
  }

  private get savedFavToastDefaults(): FavoritesToastState {
    return {
      ...defaultState,
      title: this.#i18n(translations.savedFavTitle),
      content: this.#i18n(translations.savedFavContent),
      actionText: this.#i18n(translations.savedFavActionText),
      closeAlternativeText: this.#i18n(translations.closeToast),
    }
  }

  private get alreadySavedFavToastDefaults(): FavoritesToastState {
    return {
      ...defaultState,
      title: this.#i18n(translations.alreadySavedFavTitle),
      content: this.#i18n(translations.alreadySavedFavContent),
      actionText: this.#i18n(translations.alreadySavedFavActionText),
      closeAlternativeText: this.#i18n(translations.closeToast),
      name: ToastName.AlreadySavedFavorite,
    }
  }

  private get removeFavToastDefaults(): FavoritesToastState {
    return {
      ...defaultState,
      title: this.#i18n(translations.removeFavTitle),
      content: this.#i18n(translations.removeFavContent),
      actionText: this.#i18n(translations.removeFavActionText),
      closeAlternativeText: this.#i18n(translations.closeToast),
      name: ToastName.RemoveFavorite,
      type: 'error',
    }
  }

  openSavedFavToast() {
    Object.assign(this.toastInfo, this.savedFavToastDefaults)
    this.toastInfo.isOpen = true
  }

  openAlreadySavedFavToast() {
    Object.assign(this.toastInfo, this.alreadySavedFavToastDefaults)
    this.toastInfo.isOpen = true
  }

  openRemoveFavToast() {
    Object.assign(this.toastInfo, this.removeFavToastDefaults)
    this.toastInfo.isOpen = true
  }

  close() {
    Object.assign(this.toastInfo, defaultState)
  }
}

export function isRemoveFavToastOpen(favoritesToast: FavoritesToastInterface) {
  return computed(() => {
    const { isOpen: toastIsOpen, name: toastName } = favoritesToast.toastInfo
    const toastIsRemoveFavoriteType = toastName === ToastName.RemoveFavorite

    return toastIsOpen && toastIsRemoveFavoriteType
  })
}

export type FavoritesToastInterface = FavoritesToast

export function useFavoritesToast() {
  const toastInfo: FavoritesToastState = reactive({ ...defaultState })
  const favoritesToast = new FavoritesToast(toastInfo)
  const isRemoveFavoriteToastOpen = isRemoveFavToastOpen(favoritesToast)

  return {
    favoritesToast,
    isRemoveFavoriteToastOpen,
  }
}
