export default {
  noResultTitle: {
    id: 'dashboard_my_favorites_empty_title',
    defaultMessage: 'Nothing to see here… yet',
  },
  noResultDescription: {
    id: 'dashboard_my_favorites_empty_description',
    defaultMessage: 'Keep tabs on your favorite devices by saving them here.',
  },
  noResultButtonLabel: {
    id: 'dashboard_my_favorites_empty_button_label',
    defaultMessage: 'Find your faves',
  },
}
