<template>
  <div>
    <RevCard class="relative h-full w-full hover:shadow-long">
      <FavoritesToggle
        class="absolute right-4 top-4 md:right-8 md:top-8"
        :favorite-id="favorite.favoriteUuid"
        :grade-id="favorite.gradeId"
        is-rounded
        :offer-type="favorite.offerType"
        :price="favorite.price"
        :product-uuid="favorite.productUuid"
        :zone="trackingZone"
        @removed="emit('removed')"
      />

      <RevButtonBase
        class="flex justify-start md:justify-center"
        :to="productPageLink"
        @click="trackClick"
      >
        <div class="px-16 pb-32 pt-12 md:px-24 md:py-20">
          <div
            class="flex h-full flex-row items-center md:h-256 md:flex-col md:items-start"
          >
            <div class="mr-16 shrink-0 md:relative md:mx-0 md:my-16 md:w-full">
              <RevIllustration
                :alt="favorite.image?.alt || ''"
                class="mx-auto block w-80 md:w-[138px]"
                :height="138"
                sizes="(max-width: 768px) 80px, 138px"
                :src="favorite.image?.url || ''"
                :width="138"
              />
            </div>

            <div class="flex flex-col items-start md:flex-1 md:justify-end">
              <h2
                class="body-1-bold overflow-hidden text-ellipsis text-start md:mb-4"
                :class="hasAttributes ? 'line-clamp-1' : 'line-clamp-2'"
              >
                {{ favorite.title }}
              </h2>

              <p
                v-if="hasAttributes"
                class="body-2 line-clamp-1 overflow-hidden text-ellipsis"
              >
                {{ formattedAttributes }}
              </p>

              <div class="mt-4 md:mt-8">
                <span class="body-2-bold" data-test="favorite-card-price">
                  {{ i18n.price(favorite.price) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </RevButtonBase>

      <div class="mb-24 flex justify-center">
        <RevButton
          class="justify-stretch"
          :disabled="isDisabled"
          variant="primary"
          @click="addToCart"
        >
          {{ i18n(translations.addFavoriteToCart) }}
        </RevButton>
      </div>
    </RevCard>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { Link, LinkInternal } from '@backmarket/http-api'
import { addToCart as addToCartAPI } from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'

import { DASHBOARD } from '../../routes'
import { LOG_ERROR_MESSAGE } from '../Favorites.constants'
import FavoritesToggle from '../toggle/FavoritesToggle.vue'
import type { FavoriteViewModel } from '../utils/FavoriteViewModel'

import translations from './Card.translations'

const i18n = useI18n()
const tracking = useTracking()
const logger = useLogger()
const { openErrorToast, openSuccessToast } = useTheToast()

const emit = defineEmits(['removed'])

const props = withDefaults(
  defineProps<{
    favorite: FavoriteViewModel
    position: number
  }>(),
  {
    position: 0,
  },
)

function isLinkInternal(link: Link): link is LinkInternal {
  return link.type === 'internal'
}

function isValidLink(link?: Link) {
  if (!link) {
    return false
  }

  if (isLinkInternal(link)) {
    const hasSlugV2 = !!link.params?.slugV2
    const hasUuid = !!link.params?.uuid
    const hasLocale = !!link.params?.locale

    return hasSlugV2 && hasUuid && hasLocale
  }

  return !!link.href
}

const productPageLink = computed(() => {
  const { link } = props.favorite

  if (!isValidLink(link)) {
    return { name: DASHBOARD.FAVORITES }
  }

  return {
    ...link,
    hash: {
      l: props.favorite.gradeId,
      offerType: props.favorite.offerType,
    },
  }
})

const trackingZone = computed(() => {
  return `product_my_favorites_${props.position}`
})

const hasAttributes = computed(() => {
  return !isEmpty(props.favorite.attributes)
})

const formattedAttributes = computed(() => {
  return props.favorite.attributes?.join(' - ')
})

const isDisabled = computed(() => {
  return props.favorite.isDisabled || !props.favorite.backboxListingId
})

function trackClick() {
  tracking.trackProductClick({
    product: {
      list: 'my_favorites',
      source: 'my_favorites',
      provider: '(none)',
    },
  })
}

async function addToCart() {
  if (!props.favorite.backboxListingId) {
    return
  }

  try {
    await $httpFetch(addToCartAPI, {
      body: {
        listing_id: props.favorite.backboxListingId,
      },
    })

    const toastParams = {
      title: i18n(translations.toastAddToCartTitle),
      content: i18n(translations.toastAddToCartContent),
    }
    openSuccessToast(toastParams)
  } catch (err) {
    const error = err as Error
    logger.error(LOG_ERROR_MESSAGE.ADD_TO_CART, {
      error,
      owners: ['bot-squad-lifecycle-front'],
    })

    openErrorToast()
  }

  tracking.trackAddToCart({
    product: {
      color: props.favorite.color,
      category: props.favorite.category,
      id: props.favorite.productUuid,
      listingId: props.favorite.backboxListingId,
      name: props.favorite.title,
      position: props.position,
      price: props.favorite.price.amount,
      currency: props.favorite.price.currency,
      list: 'my_favorites',
    },
  })
}
</script>
