<template>
  <RevToast
    :close-alternative-text="toastInfo.closeAlternativeText"
    :duration="toastInfo.duration"
    :name="toastInfo.name"
    :opened="toastInfo.isOpen"
    :title="toastInfo.title"
    :to="teleportTo"
    :variant="toastInfo.type"
    @close="handleClose"
  >
    <p class="mb-12">{{ toastInfo.content }}</p>

    <RevLink :to="getLink" @click="handleClick">
      {{ toastInfo.actionText }}
    </RevLink>
  </RevToast>
</template>

<script setup lang="ts">
import { useRoute, useRouteLocationWithLocale } from '#imports'
import { computed, onBeforeUnmount, watch } from 'vue'

import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevLink } from '@ds/components/Link'
import { RevToast } from '@ds/components/Toast'

import { TRACKING_PAGE_TYPE_BY_ROUTE_NAME } from '~/constants/trackingPageType'

import { DASHBOARD } from '../../../routes'

import { TRACKING_ZONE, ToastName, TrackingName } from './FavoritesToast'
import { type FavoritesToastInterface } from './useFavoritesToast'

const props = withDefaults(
  defineProps<{
    favoritesToast: FavoritesToastInterface
    teleportTo?: string
  }>(),
  {
    teleportTo: '#toast-wrapper',
  },
)

const emit = defineEmits(['undo', 'remove-favorite-toast-closed'])

const { toastInfo } = props.favoritesToast

const isRemoveFavoriteToast = computed(() => {
  return toastInfo.name === ToastName.RemoveFavorite
})

const getLink = computed(() => {
  if (isRemoveFavoriteToast.value) {
    return false
  }

  const injectLocale = useRouteLocationWithLocale()
  const link = injectLocale({ name: DASHBOARD.FAVORITES })

  return link
})

const pageType = computed(() => {
  const route = useRoute()
  const routeName = String(route.name)

  return TRACKING_PAGE_TYPE_BY_ROUTE_NAME[routeName]
})

const tracking = useTracking()

function trackLinkClick() {
  const name = TrackingName.SeeFavorites

  tracking.trackClick({ name, pageType: pageType.value, zone: TRACKING_ZONE })
}

function trackImpression() {
  const name =
    toastInfo.name === ToastName.SavedFavorite
      ? TrackingName.AddedToFavorites
      : TrackingName.AlreadyInFavorites

  tracking.trackToastView({
    name,
    pageType: pageType.value,
    zone: TRACKING_ZONE,
  })
}

function handleClose() {
  if (isRemoveFavoriteToast.value) {
    emit('remove-favorite-toast-closed')
  }

  props.favoritesToast.close()
}

function handleClick() {
  const performAction = isRemoveFavoriteToast.value
    ? () => emit('undo')
    : () => trackLinkClick()

  performAction()

  props.favoritesToast.close()
}

watch(
  () => toastInfo.isOpen,
  (isOpen) => {
    if (isOpen && !isRemoveFavoriteToast.value) {
      trackImpression()
    }
  },
  { immediate: true },
)

onBeforeUnmount(() => {
  handleClose()
})
</script>
