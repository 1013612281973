<template>
  <NoResult v-if="!hasFavorites" />

  <RevContainer v-if="hasFavorites">
    <div class="mb-32 space-y-4">
      <h2 class="heading-1">{{ i18n(translations.pageTitle) }}</h2>

      <p class="body-1">
        {{ i18n(translations.itemsCount, { count: favoritesCount }) }}
      </p>
    </div>

    <CardsList :favorites @count-updated="updateFavoritesCount" />
  </RevContainer>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevContainer } from '@ds/components/Container'

import { LOG_ERROR_MESSAGE } from './Favorites.constants'
import translations from './Favorites.translations'
import CardsList from './cards/CardsList.vue'
import { useFetchFavorites } from './composables/useFetchFavorites'
import NoResult from './no-result/NoResult.vue'
import { fromAPIFavorite as formatToFavoriteTrackingModel } from './utils/FavoriteTrackingModel'
import { fromAPIFavorite as formatToFavoriteViewModel } from './utils/FavoriteViewModel'

const i18n = useI18n()
const logger = useLogger()
const tracking = useTracking()

const { data: fetchedFavorites, error } = await useFetchFavorites()

if (error.value) {
  logger.error(LOG_ERROR_MESSAGE.FETCH, {
    owners: ['bot-squad-care-platform-front'],
  })
}

const favoritesCount = ref(0)

const favorites = computed(() => {
  if (isEmpty(fetchedFavorites.value) || favoritesCount.value === 0) {
    return []
  }

  const formattedFavorites = fetchedFavorites.value?.map((favorite) => {
    return formatToFavoriteViewModel(favorite)
  })

  return formattedFavorites || []
})

const hasFavorites = computed(() => {
  return !isEmpty(favorites.value)
})

const trackingInfo = computed(() => {
  const apiFavorites = isEmpty(!fetchedFavorites.value)
    ? fetchedFavorites.value
    : []
  const favoriteItems = apiFavorites ?? []

  const trackingFavorites = favoriteItems.map((favorite) => {
    return formatToFavoriteTrackingModel(favorite)
  })

  return {
    pageType: 'my_favorites',
    favorites: trackingFavorites,
  }
})

function trackPageView() {
  tracking.trackFavoritesPage(trackingInfo.value)
}

function updateFavoritesCount(updatedCount: number) {
  favoritesCount.value = updatedCount
}

watch(
  () => fetchedFavorites,
  () => {
    updateFavoritesCount(fetchedFavorites.value?.length || 0)
  },
  { immediate: true },
)

onMounted(() => {
  trackPageView()
})
</script>
