export default {
  pageTitle: {
    id: 'dashboard_my_favorites_title',
    defaultMessage: 'My Favorites',
  },
  itemsCount: {
    id: 'dashboard_my_favorites_items_count',
    defaultMessage: `{count, plural,
          one {# item}
          other {# items}
        }`,
  },
}
