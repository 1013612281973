export const FavoritesAction = {
  Add: 'Add',
  Remove: 'Remove',
} as const

export type FavoritesAction =
  (typeof FavoritesAction)[keyof typeof FavoritesAction]

export const FAVORITES_STORAGE_PREFIX = 'favorites_pending-action'

const featurePrefix = '[LIF][My Favorites]'

export const LOG_ERROR_MESSAGE = {
  CHECK: `${featurePrefix}[Check favorite]`,
  ADD: `${featurePrefix}[Create favorite]`,
  REMOVE: `${featurePrefix}[Remove favorite]`,
  PENDING: `${featurePrefix}[Pending action]`,
}
