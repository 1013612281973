export default {
  favoritesButtonAddLabel: {
    id: 'favorites_button_add_label',
    defaultMessage: 'Add to my favorites',
  },
  favoritesButtonRemoveLabel: {
    id: 'favorites_button_remove_label',
    defaultMessage: 'Remove from my favorites',
  },
}
