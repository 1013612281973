import type { Link, MonetaryAmount } from '@backmarket/http-api'
import type {
  Favorite,
  FavoriteImage,
} from '@backmarket/http-api/src/api-specs-favorite/models/favorite'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

export type FavoriteViewModel = {
  favoriteUuid: string
  productUuid: string
  gradeId: number
  offerType: number
  price: MonetaryAmount
  title: string
  model: string
  isDisabled: boolean

  image?: FavoriteImage
  attributes?: string[]
  link?: Link
  backboxPrice?: MonetaryAmount
  backboxListingId?: number
  category?: string
  color?: string
  brand?: string
}

export function fromAPIFavorite(favorite: Favorite): FavoriteViewModel {
  const {
    favoriteId,
    productUuid,
    gradeId,
    offerType,
    price,
    title,
    model,
    images,
    subTitleElements,
    link,
    backbox,
    trackingDetails,
  } = favorite

  const image = images && !isEmpty(images) ? images[0] : undefined
  const isDisabled = favorite.isDisabled || !favorite.backbox?.listingId

  return {
    favoriteUuid: favoriteId,
    productUuid,
    gradeId,
    offerType,
    price,
    title,
    model,
    isDisabled,
    image,
    attributes: subTitleElements || [],
    link,
    backboxPrice: backbox?.price,
    backboxListingId: backbox?.listingId,
    category: trackingDetails?.category3Name,
    color: trackingDetails?.color,
    brand: trackingDetails?.brand,
  }
}
