import type { UnwrapRef } from 'vue'

import type { Favorite } from '@backmarket/http-api/src/api-specs-favorite/models/favorite'

import type { useProductTracking } from '~/scopes/product/composables/useProductTracking'

export type FavoriteTrackingModel = Partial<{
  averageRate: number
  brand: string
  category: string
  color: string
  currency: string
  hasNewBattery: boolean
  mobileDeeplink: string
  model: string
  name: string
  price: string
  list: string
  productUuid: string
  isDisabled: boolean
  webUrl: string
  listingId: number
  variant: number
  imageUrl: string
}>

type ProductTrackingModel = UnwrapRef<
  ReturnType<typeof useProductTracking>['product']['value']
>

export function fromProductTrackingModel(
  productTrackingModel: ProductTrackingModel,
): FavoriteTrackingModel {
  return {
    averageRate: productTrackingModel.averageRate,
    brand: productTrackingModel.brand,
    category: productTrackingModel.category,
    color: productTrackingModel.color,
    currency: productTrackingModel.currency,
    hasNewBattery: productTrackingModel.hasNewBattery,
    mobileDeeplink: productTrackingModel.mobileDeeplink,
    model: productTrackingModel.model,
    name: productTrackingModel.name,
    price: productTrackingModel.price,
    list: productTrackingModel.list || 'my_favorites',
    productUuid: productTrackingModel.uuid,
    webUrl: productTrackingModel.webUrl,
    listingId: productTrackingModel.listingId,
    variant: productTrackingModel.variant,
    imageUrl: productTrackingModel.image,
  }
}

export function fromAPIFavorite(favorite: Favorite) {
  return {
    brand: favorite.trackingDetails?.brand || '',
    category: favorite.trackingDetails?.category3Name || '',
    color: favorite.trackingDetails?.color || '',
    price: favorite.price?.amount || '',
    currency: favorite.price?.currency || '',
    webUrl: favorite.link?.href || '',
    model: favorite.model,
    name: favorite.title,
    list: 'my_favorites',
    productUuid: favorite.productUuid,
    isDisabled: favorite.isDisabled,
  }
}
