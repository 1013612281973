<template>
  <div
    class="mb-20 mt-16 grid grid-cols-1 gap-16 md:mb-56 md:mt-20 md:grid-cols-[repeat(3,256px)] md:gap-32 lg:grid-cols-[repeat(4,256px)]"
  >
    <Card
      v-for="(favorite, index) in displayedFavorites"
      :key="favorite.favoriteUuid"
      data-qa="favorite-card"
      :data-test="`favorite-card-${favorite.favoriteUuid}`"
      :favorite
      :position="index"
      @removed="removeFromView(favorite.favoriteUuid)"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'

import type { FavoriteViewModel } from '../utils/FavoriteViewModel'

import Card from './Card.vue'

const emit = defineEmits<{
  (e: 'count-updated', payload: number): void
}>()

const props = withDefaults(
  defineProps<{
    favorites?: FavoriteViewModel[]
  }>(),
  {
    favorites: () => [],
  },
)

const displayedFavorites = ref([] as FavoriteViewModel[])

function removeFromView(favoriteUuid: string) {
  displayedFavorites.value = displayedFavorites.value.filter((favorite) => {
    return favorite.favoriteUuid !== favoriteUuid
  })

  const updatedCount = displayedFavorites.value.length

  emit('count-updated', updatedCount)
}

watch(
  () => props,
  () => {
    displayedFavorites.value = props.favorites
  },
  { immediate: true },
)
</script>
